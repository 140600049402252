import React from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
const { setNavBar } = iceStarkData.store.get('commonAction')?.ui;
const { departmentSelections } = iceStarkData.store.get('commonAction')?.departments;
const { getUsersList, enableOrDisabledUser, changeUserWord, sendOutInviteCode, inviteCodeChance } = iceStarkData.store.get('commonAction')?.users;
import { Button, SearchInput, DataTable, Pager, Dialog, notification, Tip, } from 'tyb';
import { ConfirmPopout } from 'ucode-components';
import { formatDate } from 'ucode-utils';

@connect(
    (state) => {
        return {
            ...state.users,
            openIds: state.users.openIds,
            departmentSelectionsList: state.departments.departmentSelectionsList,
            permissionIds: state.users.permissionIds || [],
        }
    }, {
    setNavBar,
    changeUserWord,
    getUsersList: getUsersList.REQUEST,
    enableOrDisabledUser: enableOrDisabledUser.REQUEST,
    sendOutInviteCode: sendOutInviteCode.REQUEST,
    departmentSelections: departmentSelections.REQUEST,
    inviteCodeChance: inviteCodeChance.REQUEST
}
)

class SubAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableColumns: this.getTableColumns(),
            sensOutDialog: false,
            codeChance: 0
        }
    }

    componentDidMount() {
        const { userSearch, getUsersList, departmentSelections } = this.props;
        getUsersList({ ...userSearch });
        departmentSelections();
    }

    getTableColumns = () => {
        const tableHeadArr = [
            {
                key: 'addressBookName',
                dataIndex: 'addressBookName',
                title: '账号名称/ID',
                render: (value, record) => (
                    <div>
                        <div>{value}</div>
                        <div>{record.id}</div>
                    </div>
                )
            },
            {
                key: 'roleNames',
                dataIndex: 'roleNames',
                title: '角色',
                render: (value, record) => <div className="moreline-text-ellipsis" title={value}>{value}</div>
            },
            {
                key: 'deptIds',
                dataIndex: 'deptName',
                title: '所属部门',
            },
            {
                key: 'operate',
                dataIndex: 'operate',
                title: '操作',
                render: (value, record) =>
                    <div className="table-operate-btn">
                        {/* {(this.props.openIds.userType != 'SYSTEM'&& this.props.openIds.type != 'TY') && this.props.permissionIds.includes('data.organization.user.add') && <span onClick={() => {
                            this.props.inviteCodeChance({
                                id: record.id, callback: (res) => {
                                    this.setState({ codeChance: res.result })
                                }
                            });
                            this.setState({ sensOutDialog: true, isBatch: false, userInfo: record })
                        }}>发送邀请码</span>} */}
                        {this.props.permissionIds.includes('data.organization.user.edit') && <span onClick={() => this.oprations(record, 'edit')}>编辑</span>}
                        {this.props.permissionIds.includes('data.organization.user.status') && <span
                            onClick={() => { this.props.enableOrDisabledUser({ id: record.id, status: record.status == 'ENABLE' ? 'DISABLE' : 'ENABLE' }) }}
                        >{record.status == 'ENABLE' ? '禁用' : '启用'}</span>}
                    </div>
            }
        ]
        const creatorObj = [
            // {
            //     key: 'remark',
            //     dataIndex: 'remark',
            //     title: '备注',
            //     render:(value, record) => <div className="moreline-text-ellipsis" title={value}>{value}</div>
            // },
            {
                key: 'loginTime',
                dataIndex: 'loginTime',
                title: '登录时间',
                render: (value, record) => <div>{value ? formatDate(value) : ''}</div>
            }
        ]
        if (this.props.openIds.userType == 'SYSTEM') {
            creatorObj.forEach(v => {
                tableHeadArr.splice(3, 0, v);
            })
        } else {
            tableHeadArr.splice(0, 0, {
                type: 'selection',
                width: 40,
                dataIndex: 'id'
            })
        }
        return tableHeadArr;
    }

    renderFilterDeptName = (col) => {
        const { userSearch, getUsersList, departmentSelectionsList } = this.props;
        const value = userSearch[col.key] ? userSearch[col.key] : []
        return (
            <DataTable.FilterableColumnHeader
                list={departmentSelectionsList}
                value={value}
                onFilter={value => {
                    getUsersList({ ...userSearch, [`${col.key}`]: value, page: 0 })
                }}
            >
                {col.title}
            </DataTable.FilterableColumnHeader>
        )
    }

    getColumns = () => {
        return this.state.tableColumns.map(column => {
            if (column.key === 'deptIds') {
                return {
                    ...column,
                    headerRender: this.renderFilterDeptName
                }
            } else {
                return column;
            }
        });
    }

    oprations = (record, action) => {
        const editPath = 'dataorganization/datauseredit';
        const { id } = record;
        switch (action) {
            case 'create':
                this.props.setNavBar('新增账号');
                this.props.history.push(editPath);
                break;
            case 'edit':
                this.props.setNavBar("账号编辑");
                this.props.history.push(`${editPath}?${queryString.stringify({ id })}`);
                break;
            case 'code':
                const { sendOutInviteCode } = this.props;
                const { isBatch, selections, userInfo = {} } = this.state;
                let arr = [];
                if (userInfo.id) {
                    arr.push(userInfo.id);
                }
                sendOutInviteCode({
                    ids: isBatch ? selections : arr, callback: (res) => {
                        notification.success('发送邀请码成功');
                        this.setState({ sensOutDialog: false, selections: [] })
                    }
                });
                break;
            default:
                break;
        }
    }

    handleSelectionChange = (key, record, checked) => {
        const { selections = [] } = this.state
        if (checked) {
            if (selections.indexOf(key) < 0) {
                this.setState({
                    selections: [...selections, key]
                })
            }
        } else {
            this.setState({
                selections: selections.filter(selected => selected !== key)
            })
        }
    }

    handleSelectionAllChange = (checked) => {
        this.setState({
            selections: checked ? this.props.userTableData.map(d => d.id) : []
        })
    }

    render() {
        const { sensOutDialog, selections = [], isBatch = false, userInfo = {}, codeChance } = this.state;
        const { openIds, userSearch, userTableData, totalElements, userLoading, perPageCountList, getUsersList, changeUserWord, permissionIds } = this.props;
        let listStyle = userTableData.length > 0 && !userLoading;
        return (
            <div className={`table-padding-bottom ${listStyle ? 'table-tr-height50' : 'table-tr-middle'}`}>
                <div className="searchbar-box">
                    {permissionIds.includes('data.organization.user.add') && <Button style={{ marginRight: 10 }} onClick={() => this.oprations({}, 'create')}>新增账号</Button>}
                    {/* {openIds.userType != 'SYSTEM' && openIds.type != 'TY'&& permissionIds.includes('data.organization.user.add') && <Button
                        className="tc-15-btn weak"
                        onClick={() => {
                            this.setState({ codeChance: 0 });
                            if (selections.length == 0) {
                                notification.error('请勾选发送人员');
                            } else {
                                this.setState({ sensOutDialog: true, isBatch: true })
                            }
                        }}>批量发送邀请码</Button>} */}
                    <SearchInput
                        className="search-input-block"
                        placeholder="输入姓名手机号邮箱搜索"
                        onChange={(value) => changeUserWord({ word: value })}
                        onSearch={() => { getUsersList({ ...userSearch, page: 0 }) }}
                        value={userSearch.word}
                    />
                </div>
                <DataTable
                    columns={this.getColumns()}
                    data={userTableData}
                    rowKey="id"
                    rowClassName={(value, record) => value.status == 'DISABLE' ? 'table-gray-row' : ''}
                    messageRender={({ data, error }) => {
                        if (error != null) { return error.msg; }
                        if (data == null || data.length === 0) {
                            return permissionIds.includes('data.organization.user.add') ?
                                <span className="text-weak">暂无子账号，您可以点击左上角<span onClick={() => this.oprations({}, 'create')}>新增账号</span></span> :
                                <span className="text-weak">暂无子账号</span>;
                        }
                    }}
                    loading={userLoading}
                    selection={{
                        value: selections,
                        onChange: this.handleSelectionChange,
                        onChangeAll: this.handleSelectionAllChange,
                    }}
                />
                {listStyle && <div className="page-padding20">
                    <Pager
                        total={totalElements}
                        current={userSearch.page}
                        perPageCount={userSearch.size}
                        perPageCountList={perPageCountList}
                        onPageChange={e => { getUsersList({ ...userSearch, page: e }); this.setState({ selections: [] }) }}
                        onPerPageChange={e => { getUsersList({ ...userSearch, size: e, page: 0 }); this.setState({ selections: [] }) }} // 每页大小
                    />
                </div>}

                <Dialog
                    style={{ width: 330 }}
                    title="发送邀请码提示"
                    visible={sensOutDialog}
                    onCancel={() => { this.setState({ sensOutDialog: false }) }}
                    onOk={() => {
                        if (codeChance == 3) {
                            notification.error('今日操作已达上限【3】');
                            return;
                        };
                        this.oprations({}, 'code')
                    }}
                >
                    {!isBatch && <Tip type="warning" hasClose={false}>
                        每日每个账号可发送3次，您还可发送{3 - codeChance}次!
                    </Tip>}
                    <div style={{ color: '#888' }}>
                        {<p>请确认发送邀请码至<span style={isBatch ? {} : { color: 'red' }}>{isBatch ? '勾选人员' : userInfo.addressBookName}</span>吗？</p>}
                    </div>
                </Dialog>
            </div>
        )
    }

}

export default SubAccount;